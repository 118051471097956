
.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.raise-1 {
    z-index: 1;
}

.raise-2 {
    z-index: 2;
}

.raise-3 {
    z-index: 3;
}
 
.raise-4 {
    z-index: 4;
}

.lower-1 {
    z-index: -1;
}

.lower-1 {
    z-index: -2;
}

.lower-3 {
    z-index: -3;
}

.lower-4 {
    z-index: -4;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    from {transform:rotate(360deg);}
}

.spin {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    border-radius: 6px;
    border: solid 4px $gray-200;
    margin-bottom: 20px;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.connection-search {

    .employment-circle {

        .logo-circle {
            border: solid 3px white;
    
    
            color: $white;
            margin: 2px;
            border-radius: 100%;
            width: 40px;
            text-align: center;
            height: 40px;
            -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    
            img {
                border-radius: 100%;
            }
        }
    
        .employment-circle-inner {
            border: solid 3px white;
            background-color: $green;
            padding-top: 5px;
            color: $white;
            margin: 2px;
            border-radius: 100%;
            width: 40px;
            text-align: center;
            height: 40px;
            -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    
            &.employment-circle-inner-green {
                background-color: rgb(77, 179, 106);
            }
            &.employment-circle-inner-yellow {
                background-color: rgb(235, 174, 17);
            }
            &.employment-circle-inner-orange {
                background-color: rgb(235, 121, 17);
            }
            &.employment-circle-inner-pink {
                background-color: rgb(224, 114, 114);
            }
            &.employment-circle-inner-blue {
                background-color: rgb(88, 155, 255);
            }
            &.employment-circle-inner-purple {
                background-color: rgb(131, 88, 255);
            }
    
        }
    
    }

}

@media(min-width: 1000px) {
    .connection-search {
        // height: 628px;
    }
}


#archk-try-it-out {
    position: relative;
    left: -10px;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: run;
    -webkit-animation-duration: 7s;
    transition: all .2s;
    font-weight: bold;
  }

@keyframes run {
    0% {
        left: -30px;
        color: $green;
    }
    50% {
        left: 30px;
        color: $tealOriginal;
    }
    100% {
        left: -30px;
        color: $green;
    }

  
}


// .mesh-3d {
//     background-color: #e5e5f7;
//     opacity: 0.8;
//     background: linear-gradient(135deg, #444cf755 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, #444cf7 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, #444cf755 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, #444cf7 25%, #e5e5f7 25%) 0px 0/ 20px 20px;
// }

.animated-hero {
    background-color: $teal;
	background: linear-gradient(-45deg, $orange, $purple, $teal, $green);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}




.iphone, .ipad {
	display: block;
	color: #fff;
	text-align: center;
	font-family: 'SF Pro Display', Helvetica Neue, Arial, sans-serif;
	letter-spacing: -.022em;
	font-size: 2.5vmin;
	text-shadow: 0 0 0.5em #000;
	background-color: #000;
	background-repeat: no-repeat, no-repeat;
	border: solid #111;
	margin-bottom: 2em;
	position: relative;
	transition: all .1s linear, line-height 0s linear;
    background-size: cover;
	&:hover {
		color: transparent;
		text-shadow: 0 0 0em transparent;
		background-position: 55% -100%, center center;
		transition: all .15s linear, line-height 5s linear;
		line-height: 0vmin;
	}
}

.iphone {
	$unit: 90px;
	width: $unit * 1.1;
	height: 2.15*$unit;
	line-height: 2.165*$unit;
	border-width: $unit/15.625;
	border-radius: $unit/5.86;
	z-index: 200;
    background-position: center;
    top: -$unit;
    margin-left: 20px;
}

.ipad {
	$unit: 250px; 
	height: 1.43*$unit;
	width: $unit;
	line-height: 1.43*$unit;
	border-width: $unit/20;
	border-radius: $unit/14;
	z-index: 100;
}


.gradient-border {
    --borderWidth: 6px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
    z-index: 2;
    transform: perspective(100px) translateZ(5px);

    img {
        // transform: translateX(11px) translateY(20px) translateZ(75px) perspective(200px);
    }
}
  .gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
    border-radius: 100%;

  }
  
  
  @keyframes animatedgradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  
  
  
  