$sidebarWidth: 225px;
$dark-100: #2E3047;
$dark-200: #43455C;
$dark-300: #3C3F58;
$dark-400: #1f2131;

$gray-100: #9098b8;
$gray-200: #ddd;
$white: #FFF;

$green: #3bba9c;

.archk-docs-body {
    background: $dark-400;
}

#archk-docs {

    .archk-docs-sidebar {

        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: $sidebarWidth;
        border-right: solid 1px $gray-200;
        background: $dark-100;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: auto;

        .archk-docs-sidebar-logo {
            color: $white;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: .25rem;
            padding-bottom: .25rem;
            max-width: 100%;
            margin-bottom: 20px;
            cursor: pointer;
        }

        ul {

            margin-bottom: 0;
            padding-left: 0;

            li {
                
                .archk-docs-sidebar-wrapper {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                    list-style: none;
                    color: $white;
                    background: $dark-100;
                    display: block;
                    font-size: 14px;
                    line-height: 20px;

                    transition: all .3s;

                    &.active {
                        background: $dark-300;
                        margin-right: 2rem;
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }

                    &:hover {
                        background: $dark-200;
                        cursor: pointer;
                    }
                }

            }


            ul {
                
                li {


                    .archk-docs-sidebar-wrapper {
                        padding-left: 2rem;

                        color: $gray-100;

                    }

                }

            }

        }

    }

    .archk-docs-main {

        margin-left: $sidebarWidth;
        min-height: 100vh;
        background: $dark-400;
        padding-top: 1rem;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
            margin-bottom: .3rem;
        }

        h3 {
            line-height: 1.5;
        }

        p, li, a, pre {
            color: $gray-100;
        }

        p, pre {
            font-size: 14px;
            line-height: 22px;
        }

        pre {
            margin-bottom: 0;
            font-size: 12px;
            color: $gray-200;
        }

        hr {
            border-bottom: solid 1px $gray-100;
        }

        code {
            font-weight: bold;
            color: $green;
        }

        img {
            max-width: 100%;
            border-radius: 6px;
            border: solid 4px $gray-200;
            margin-bottom: 20px;
        }

        .archk-docs-main-logo {
            img {
                border-radius: none;
                border: none;
                padding-left: 1rem;
                padding-right: 1rem;;
            }
           
        }

        .video-container {
            overflow: hidden;
            position: relative;
            width:100%;
            border-radius: 6px;
            border: solid 4px $gray-200;
            margin-bottom: 20px;
        }
        
        .video-container::after {
            padding-top: 56.25%;
            display: block;
            content: '';
        }
        
        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .table-responsive {
            border-radius: 6px;
            border: solid 1px $gray-200;
        }

        .table {

            margin-bottom: 0;

            thead {

                border-top: none;
                
                tr {

                    color: $white;

                    &:hover {
                        background: #525f7f;
                    }

                    th {
                        border-top: none;
                    }

                }
            }
            tr {

                background: $dark-200 !important;
                color: $gray-200;
                border-top: none;
                // border-left: solid 1px $gray-200;
                // border-right: solid 1px $gray-200;

                &:last-child {
                    // border-bottom: solid 1px $gray-200;
                }

                td:nth-child(1) {
                    width: 150px;
                    color: $white;
                }
                td:nth-child(2) {
                    width: 100px;
                }

                &:hover {
                    background: inherit;
                    border-left: solid 1px $gray-200;
                    border-radius: 0;

                    td:first-child::before {
                        position: static;
                        background: red;
                        content: '';
                    }
    
                    td:last-child::before {
                        position: static;
                        content: '';
                    }
                }
            }
        }

        .card {
            border: solid 1px $gray-200;
            background: transparent;
            border-radius: 6px;
            position: sticky;
            top: 0;

            &.not-sticky {
                position: static;
            }

            .card-header {
                background: $dark-300;
                border: none;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                .card-title {
                    color: $white;
                }
            }
    
            .card-body, .card-footer {
                background: $dark-200;
                border: none;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                code {
                    font-size: 14px;
                    color: $white;
                }
                
                p {
                    color: $gray-200;
                }
                
            }

            .badge {
                min-width: 60px;
            }

        }

        .archk-docs-main-content {
            padding: 1rem;

            p:last-child {
                margin-bottom: 0;
            }
        }

        .archk-docs-main-content-header, .archk-docs-main-content-sub-header {
            // margin-top: 2rem;
        }

        
    }

    @media(max-width: 768px) {
        .card {
            margin-top: 1rem;;
        }
    }

    @media(max-width: 576px) {
        .archk-docs-sidebar {
            display: none;
        }
        .archk-docs-main {
            margin-left: 0;
        }
    }

}