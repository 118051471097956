.dropdown-menu.show {

    background: white;
    border-radius: 0;
    padding-bottom: 0;
    padding-top: 0;
    // border-radius: $border-radius / 2;
    border: solid 1px $gray-500;
    
    h6, a {
        color: $body-color;
    }

    .dropdown-item {
        border-top: solid 1px $gray-300;

        &:hover {
            cursor: pointer;
        }

        &:last-child {
            // border-bottom-left-radius: $border-radius / 2;
            // border-bottom-right-radius: $border-radius / 2;
        }
    }

    .dropdown-header {
        background: $gray-200;
        margin-top: 0;
        // border-top-left-radius: $border-radius / 2;
        // border-top-right-radius: $border-radius / 2;
    }

}
