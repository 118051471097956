#page-landing-1 {

    div, span, p, h1, h2, h3, h4,h5 {
        font-family: DINMedium !important;
    }

    .hero {
        img {
            width: 100%;
        }
    }

    p {
        font-size: 26px;
        line-height: 25px;
        font-family: DINLight !important;

        &.text-sm {
            font-size: 18px !important;
        }
    }

    .navigation {
        background: black;

        .btn {
            height: 42px;
            line-height: 35px;
        }
    }

    .connection-search {
        .btn {
            height: auto;
            line-height: 30px;
        }
    }

    .btn {
        border-radius: 10px;
        height: 75px;
        line-height: 70px;
        min-width: 188px;
        font-weight: bold;
        border-width: 2px;

        &.btn-bold {
            font-weight: bold;
            font-size: 24px;
        }

        &.btn-outline-info-dark {
            border-color: #2c79e4;
            color: #2c79e4;
            &:hover {
                background-color: #2c79e4;
                color: white
            }
        }
    }

    .display-2 {
        font-size: 40px !important;
        line-height: 40px;
        letter-spacing: 0px;
    }

    .display-3 {
        font-size: 40px !important;
        line-height: 40px;
    }

    .quote {
        font-size: 44px;
        line-height: 50px;
    }

    .quote-mark {
        font-size: 100px;
        position: absolute;
    }

    .quote-mark-left {
        top: -30px;
        left: -40px;
    }
    .quote-mark-right {
        bottom: 30px;
        right: 0px;
        line-height: 0;
    }

    .text-teal {
        color: #2dcecb !important;
    }

    .text-info-dark {
        color: #2c79e4 !important;
    }

    .bg-teal {
        background: #2dcecb !important;
    }



    @media (min-width: 1140px) {
        .container-inner {
            max-width: 900px;
        }
    }


}